@import '../../styles/customMediaQueries.css';



.root {
  fill: var(--matterColorNegative);
}

.filled {
  fill: var(--marketplaceColor);
}
