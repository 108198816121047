@import '../../styles/customMediaQueries.css';


.root {
   composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}
.userTypes {
  margin: 1rem 0;
}

.userTypes legend {
  display: block;
  margin-bottom: 12px;
}
.password {
   composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
   composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
   composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.locationContainer {
  margin-top: 1.5em;
}

.contractsInfo {
  margin: 1.5rem 0;
  border: 1px solid var(--signalisePurple);
  padding: 5px;
}
.contractsInfoAlt {
  margin: 1.5rem 0;
  color: white;
  background-color: var(--signalisePurple);
  padding: 5px;
}
