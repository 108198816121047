@import '../../styles/customMediaQueries.css';

.root {
}
.bookingCard {
  margin: 25px;
  background-color: #5d00c710;
  border-radius: 25px;
  margin-bottom: 1em;
  border: 4px solid var(--signalisePurple);
  min-width: 400px;
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  .info {
    display: grid;
    grid-template-columns: 0px 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding: 20px 20px 0 5px;
    gap: 20px;
    div {
      padding-bottom: 10px;
    }
  }

  .paddingtop {
    padding-top: 20px;
  }
  
  .start {
    grid-column: 2 / 4;
  }
  
  .end {
    grid-column: 4/5;
  }
  
  .location {
    grid-column: 4/5;
  }

  .refDateTime {
    display: flex;
    flex-direction: column;
    padding-right: 3em;
  }

  .date {
    @media (--viewportMedium) {
      padding-top: 10px; /* TODO in flex */
    }
  }

  .time {
    margin: 0px;
    gap: 1em;
    display: flex;
    flex-direction: row;
  }

  .bookingDetails {
    max-width: 100px;
    min-width: 100px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 150px;
      min-width: 150px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 170px;
      min-width: 170px;
      padding-right: 2em;
    }
  }

  .location {
    max-width: 125px;
    min-width: 125px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 200px;
      min-width: 200px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 200px;
      min-width: 200px;
      padding-right: 2em;
    }
  }

  .locationName {
    max-width: 150px;
    min-width: 150px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 225px;
      min-width: 225px;
      padding-right: 3em;
    }
    @media (--viewportLarge) {
      max-width: 225px;
      min-width: 225px;
      padding-right: 3em;
    }
  }

  .cpInfo {
    max-width: 100px;
    min-width: 100px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 150px;
      min-width: 150px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 170px;
      min-width: 170px;
      padding-right: 2em;
    }
  }
}
