@import '../../styles/customMediaQueries.css';

h2 {
  /* Adjust heading margins to work with the reduced body font size */
  margin: 29px 0 13px 0;

  @media (--viewportMedium) {
    margin: 32px 0 0 0;
  }
}

.listContainer {
  list-style-type: disc;
  list-style: inside;
  margin-top: 8px;
}

li {
  line-height: 1.6;
}
.hoppingHand {
  display: block;
  margin-top: 25px;
  max-height: 250px;
  max-width: 100%;
  object-fit: contain;
}
