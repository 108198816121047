@import '../../styles/customMediaQueries.css';

.root {
  h1 {
    margin: 29px 0 14px 0;

    @media (--viewportMedium) {
      margin: 32px 0 32px 0;
    }
  }

  & h2 {
    text-align: center;
    scroll-margin-top: 90px;
    margin-top: 60px;
  }
  & h3 {
    margin-top: 40px;
  }

  & ul li {
    line-height: 1.6;

    @media (--viewportMedium) {
      font-size: 18px;
    }
  }
}

.listContainer {
  composes: signaliseListStyle from global;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageTitle {
  text-align: center;
}

.intro {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
  }
}

.hand {
  max-height: 100px;
  display: block;
  margin: auto;
  object-fit: contain;

  @media (--viewportMedium) {
    max-height: 125px;
    margin: 0;
  }

  @media (--viewportLarge) {
    max-height: 150px;
  }
}

.bookingInfoHelp {
  margin: 1rem 0 2rem;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }
}

.contentWrapper {
  max-width: 750px;
  margin: auto;
}

.contentSection {
  margin-bottom: 2rem;
}
.contentSection:last-child {
  margin-bottom: 0;
}

.teamBio {
  margin: 1rem 0 2rem;
}
.teamBioContact {
  display: block;
  margin-bottom: 12px;
}
/* job share */
h3 span em {
  font-weight: 400;
  margin-left: 8px;
}

.runningLeftHand {
  display: block;
  margin-top: 25px;
  max-height: 250px;
  max-width: 100%;
  object-fit: contain;
}

.bookingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 260px;
  margin: 25px auto 45px;
  @media (--viewportMedium) {
    display: block;
    max-width: 200px;
  }
  /* Margin */
  margin-bottom: 48px;
}

.introLinks {
  text-align: center;
}
