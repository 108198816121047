@import '../../styles/customMediaQueries.css';

.root {
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.intro {
  margin: 0 0 30px;
}

.contentMain {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 750px;
  }
}

.subtitle {
  composes: h3 fontWeightSemiBold from global;
  margin-top: 32px;
  margin-bottom: 16px;
}

.badgeContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 35px 0;
}

.badge {
  max-height: 180px;
  max-width: 180px;
  object-fit: contain;
}

.runningLeftHand {
  display: block;
  margin-top: 25px;
  max-height: 200px;
  object-fit: contain;
}
