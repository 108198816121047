@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  /* TODO */

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.predictionsRoot li:nth-child(1) {
  /* display: none; */
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.accountSettingsAlert {
  margin: 3rem 0;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin: 3rem 0 0;
  }
}
.hand {
  max-height: 100px;
  object-fit: contain;
  @media (--viewportMedium) {
    margin-right: 1rem;
  }
}

.bookingInfoHelp {
  display: flex;
  margin: 12px 0;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.datePicker {
  width: 100%;
  margin: 12px 0;

  @media (--viewportMedium) {
    width: 45%;
  }
}

.dateMessage {
  margin-top: 2rem;
}
