@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
}

.footer {
  display: flex;
  margin-top: 8px;
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}

/* TODO merge conflict
.resetAllButton {
  composes: a h5 from global;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  /*margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    /*margin-top: 10px;
    margin-bottom: 14px;
  }
}
*/
.resetAllButton {
  composes: filterButton;
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  composes: a h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  composes: a h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
