@import '../../styles/customMediaQueries.css';

.EditListingDescriptionForm_formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;
}

  .requiredDocuments {
    composes: signaliseListStyle from global;
  }

  .features ul {
    list-style-type: none;
  }

  .pageHeading {
    composes: h2 fontWeightSemiBold from global;
    margin-bottom: 0;
  

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.elementMargin {
  padding: 0;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 30px;
  }
}

div.inputContainer {
  display: flex;
  align-items: center;
  margin: 20px 0 40px;
}

.upload::-webkit-file-upload-button {
  visibility: hidden; /* hides Chrome browser default input label */
}
.upload::before {
  content: 'Choose a file';
  display: inline-block;
  background: #f2f1f1;
  border: 1px solid #bababa;
  border-radius: var(--borderRadius);
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.85rem;
}

.uploadField {
  margin: 20px 0;
}
.uploadButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  max-width: 175px;
}
.error {
  color: var(--failColor);
}

.title {
  composes: EditListingDescriptionForm_formMargins;
}

.description {
  composes: EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.registration {
  composes: EditListingDescriptionForm_formMargins;
}
.formInputHelperInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}
.successMessage {
  margin: 2rem 0;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.alertHand {
  max-height: 100px;
  object-fit: contain;
  @media (--viewportMedium) {
    margin-right: 1rem;
  }
}

.helperText {
  margin: 5px 0 0;
  font-size: 0.9rem;
  color: #686868;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
