@import '../../styles/customMediaQueries.css';



.root {
}

.fillColor {
  fill: var(--successColor);
}
