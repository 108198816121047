@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
    background-color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 18px;
  text-align: center;
  vertical-align: middle;
}

.icon:nth-child(3) {
  margin-left: -5px;
}

/* social media icons */
.icon svg {
  fill: var(--matterColorAnti);

  &:hover {
    fill: var(--signaliseGreen);
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 1;
  flex-basis: 210px;
  display: none; /* hides logo on small screens */
  margin-right: 24px;
  margin-bottom: 24px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 40px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 270px;
  }
}

.logoLink {
  display: block;
  margin: 0 0 14px -7px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
}
.logo svg {
  fill: var(--matterColorLight);
  &:hover {
    fill: var(--signaliseGreen);
  }
}

.organizationDescription {
  color: var(--matterColorLight);
  composes: marketplaceTinyFontStyles from global;
}

.organizationCopyright {
  margin-top: 24px;
  composes: marketplaceTinyFontStyles from global;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--signaliseGreen);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinksHeader {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  margin-top: 12px;
  font-size: 19px;
  line-height: 24px;

  @media (min-width: 768px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.socialHeading {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 24px;
  font-size: 19px;
  line-height: 24px;

  @media (min-width: 768px) {
    margin-top: 16px;
    margin-bottom: 0px;
  }
}

.infoLinks,
.socialLinks {
  order: 2;
  flex-basis: 175px;
  margin-top: 12px;
  margin-right: 24px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
  }
}

.socialLinks {
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.socialLinksContainer {
  margin-top: 12px;
  margin-bottom: 16px;
}
.socialLinks {
  order: 4;
}

/* External organisation logos */
.externalOrgLogos {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
  }
  @media (--viewportMedium) {
    justify-content: center;
  }
}

.externalOrgLogo {
  /* display: block; */
  object-fit: contain;
  max-height: 80px;
  margin-top: 8px;
  align-self: center;
  padding: 5px;
  flex-shrink: 1;
}

.dc {
  background-color: white;
}
.wc {
  padding: 0;
}
.list {
  margin-top: 12px;
  margin-bottom: 0;
  list-style: none;
}

.listItem {
  min-height: 24px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    padding-bottom: 6px;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 24px;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 14px;

  &:hover {
    color: var(--signaliseGreen);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.link.contact {
  padding: 0 0 8px;
}
.link.contact:first-of-type {
  display: block;
  margin-bottom: 12px;
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 150px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-top: 12px;

  /* Layout */
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}
.tosAndPrivacy li {
  margin-bottom: 14px;
}
.tosAndPrivacy li a {
  font-size: 17px;
}
.legalLink,
.mailchimpLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--signaliseGreen);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.mailchimpLink {
  display: block;
  border: 2px solid var(--signaliseGreen);
  margin: 12px 0 0 -4px;
  color: var(--signaliseGreen);
  padding: 7px 5px;
  border-radius: var(--borderRadius);
  white-space: nowrap;
  transition: ease-in-out 0.25s;
  &:hover {
    background-color: var(--signaliseGreen);
    color: var(--matterColorDark);
    text-decoration: none;
  }
}
/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Layout */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    display: none;
  }
}
.copyrightAndTermsMobile span {
  color: var(--matterColorAnti);
  margin-bottom: 20px;
}
.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 280px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--signaliseGreen);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
