@import '../../styles/customMediaQueries.css';


.root {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton);
  &:hover {
    fill: var(--marketplaceColor);
  }
}
