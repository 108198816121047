@import '../../styles/customMediaQueries.css';




.EditListingDescriptionForm_formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.primaryButtonRoot {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
  margin-top: 25px;
}

.error {
  color: var(--failColor);
}

.formInputHelperInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.subscribeForm {
  margin-top: 25px;
}

label {
  display: block;
}

/* add some margin between text inputs [name email] */
.subscribeForm {
  & input[type='text'] {
    margin-bottom: 25px;
  }
}

/* radio buttons */
.fieldset {
  margin: 25px 0;
}

.fieldset legend {
  margin-bottom: 25px;
}
/* remove browser styling and add custom radio button styles */
.label {
  display: block;
  margin-bottom: 25px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.label input[type='radio'],
.check input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.label span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #ddd;
  border-radius: 50%;
  transition: 0.3s background-color;
}

.label span:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.label:hover span {
  background-color: #ccc;
}
.label input:checked ~ span {
  background-color: var(--signalisePurple);
}
.label input:checked ~ span:after {
  display: block;
}

.label.check span {
  border-radius: 0;
}
.marketingAcceptance {
  margin: 25px 0 50px;
}
