@import '../../styles/customMediaQueries.css';

:root {
  --SectionInfo_desktopChoiceMaxWidth: 625px;

  --SectionInfo_animation: {
    animation-name: animationChoice;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

.SectionInfo {
  display: flex;
}
/* Animation for the info elements */

@keyframes animationChoice {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationChoice;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.infoContentSection {
  display: flex;
  flex-direction: column;
  max-width: 64rem;
  margin: auto;
  gap: 15px;

  @media (min-width: 600px) {
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: row;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
    width: 100%;
    flex-direction: row;
  }
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 200px;
  margin: 15px 15px 30px;

  @media (--viewportMedium) {
    flex-basis: 250px;
  }
}

.infoHand {
  width: 125px;
  object-fit: contain;

  @media (--viewportMedium) {
    width: 150px;
  }
}
.infoContent h1 {
  height: 100px;
  line-height: 1.5;
}

.infoChoice {
  color: var(--marketplaceColorDark);
  font-size: 22px;
  text-align: center;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionInfo_desktopChoiceMaxWidth);
  }
}

.infoButton {
  composes: button buttonFont buttonText buttonBorders from global;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    max-width: 200px;
  }
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  &:hover {
    color: var(--signaliseGreen);
    background-color: var(--signaliseDarkPurple);
  }
}
/* over-rides the default marketplace settings */
.infoButton {
  color: var(--matterColorLight);
  margin-bottom: 30px;
}
.infoButton:hover {
  color: var(--signaliseGreen);
  background-color: var(--signaliseDarkPurple);
}
