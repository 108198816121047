@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layoutWrapperMain {
  /* Use all available space parent element (aka root) can give and don't shrink content */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  /* Expand to the full remaining width of the viewport */

  width: 100%;
}
