@import '../../styles/customMediaQueries.css';

.root {
  & h1 {
    margin: 29px 0 14px 0;

    @media (--viewportMedium) {
      margin: 32px 0 32px 0;
    }
  }

  & h2 {
    text-align: left;
    scroll-margin-top: 90px;
    margin-top: 60px;
  }
  & h3 {
    margin-top: 40px;
  }
  ul {
    list-style-type: disc;
    list-style: inside;
    margin-top: 8px;
  }

  li {
    line-height: 1.6;
  }
}
html {
  scroll-padding-top: 75px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageTitle {
  text-align: center;
}

.intro {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
  }
}

.image {
  width: 100%;
  display: block;
  margin: auto;
}

.bookingInfoHelp {
  margin: 1rem 0 2rem;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }
}

.contentWrapper {
  max-width: 750px;
  margin: auto;
}

.contentSection {
  margin-bottom: 2rem;
}
.contentSection:last-child {
  margin-bottom: 0;
}

.teamBio {
  margin: 1rem 0 2rem;
}
.teamBioContact {
  display: block;
  margin-bottom: 12px;
}
/* job share */
h3 span em {
  font-weight: 400;
  margin-left: 8px;
}

.runningLeftHand {
  display: block;
  margin-top: 25px;
  max-height: 250px;
  max-width: 100%;
  object-fit: contain;
}

.bookingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  /* max-width: 260px; */
  margin: 25px auto 45px;

  /* Margin */
  margin-bottom: 48px;
}

.introLinks {
  text-align: center;
}

.enquiryButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  display: block;
  max-width: 260px;

  /* Margin */
  margin-bottom: 48px;
}
