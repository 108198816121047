


.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addToFavouritesIconContainer,
.addToFavouritesSpinnerContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.addToFavouritesIcon,
.addToFavouritesSpinnerIcon {
  height: 24px;
  width: 24px;
}
.addToFavouritesIcon {
  transition: ease-in-out 0.5s;
}
.addToFavouritesIcon:hover {
  transform: scale(1.1);
}
.addToFavouritesSpinnerIcon {
  fill: var(--heartIconColor);
  stroke: var(--heartIconColor);
}

.activeFavouritesIcon {
  fill: var(--heartIconColor);
  stroke: var(--heartIconColor);

  & path {
    fill: var(--heartIconColor);
    stroke: var(--heartIconColor);
  }
}
