@import '../../styles/customMediaQueries.css';

h2 {
  /* Adjust heading margins to work with the reduced body font size */
  margin: 29px 0 13px 0;

  @media (--viewportMedium) {
    margin: 32px 0 0 0;
  }
}

.listContainer {
  composes: signaliseListStyle from global;
}

li {
  line-height: 1.6;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.handSection {
  max-width: 100%;
}

.runningHand {
  max-width: 100%;
  display: block;
  margin-top: 30px;
  max-height: 250px;
  object-fit: contain;
}
