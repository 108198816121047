@import '../../styles/customMediaQueries.css';

.container {
  max-width: 700px;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  @media (max-width: 568px) {
    max-width: 400px;
  }
}

.panelTitle {
  text-align: center;
  letter-spacing: 0.5px;
  margin-top: 0;
}

.successContainer {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  margin: 2rem auto;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  align-items: center;
  text-align: center;

  & h2 {
    line-height: 1.4;
    text-align: center;
  }

  @media (max-width: 568px) {
    max-width: 400px;
  }
}

.successHeader {
  background: #64b612;
  padding: 1rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
}

.infoSection {
  margin: 30px 0;
  text-align: left;
}

.linkButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin: auto;

  @media (--viewportMedium) {
    display: block;
    max-width: 200px;
  }
}
