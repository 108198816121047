@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.registration {
  composes: formMargins;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.formInputHelperInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.sectionContainer {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.editBookingAlert {
  margin: 1rem 0 2rem;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }
}

.hand {
  max-height: 100px;
  object-fit: contain;
  @media (--viewportMedium) {
    margin-right: 1rem;
  }
}

.field {
  margin: 24px 0;
}
