@import '../../styles/customMediaQueries.css';

.root {
  width: 500px;
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.bottomWrapper {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.helperLink {
  composes: a from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.field {
  margin-top: 10px;
}

.saveBookingButton {
  margin: 10px 10px 10px 0;
}

.editFormMessage {
  color: white;
  background-color: var(--signalisePurple);
  padding: 10px;
}
