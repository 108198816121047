@import '../../styles/customMediaQueries.css';

.root {
  h1 {
    margin: 29px 0 14px 0;

    @media (--viewportMedium) {
      margin: 32px 0 32px 0;
    }
  }

  h2 {
    text-align: center;
  }
  h3 {
    margin-top: 40px;
  }

  li {
    line-height: 1.6;
    @media (--viewportMedium) {
      font-size: 18px;
    }
  }
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageTitle {
  text-align: center;
}

.intro {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}

/*.videoSection {
  margin: 40px 0 30px;
}
*/
.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hand {
  max-height: 100px;
  display: block;
  margin: auto;
  object-fit: contain;

  @media (--viewportMedium) {
    max-height: 125px;
    margin: 0;
  }

  @media (--viewportLarge) {
    max-height: 150px;
  }
}

.contentWrapper {
  max-width: 750px;
  margin: auto;
}

.sectionContent {
  margin: 3rem 0;
}
.sectionContent:last-child {
  margin-bottom: 0;
}

span em {
  font-weight: 400;
  margin-left: 8px;
  display: inline-block;
  width: 120px;
}

.runningLeftHand {
  display: block;
  margin: 25px auto 25px;
  max-height: 200px;
  max-width: 100%;
  object-fit: contain;
}
.hoppingHand {
  display: block;
  margin: 25px auto 25px;
  max-height: 200px;
  max-width: 100%;
  object-fit: contain;
}
span.instruction {
  display: block;
  max-width: 600px;
  text-align: center;
}
.userType {
  margin: 3rem 0;
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }
  span.instruction {
    text-align: left;
  }
}

.userTypeImg {
  display: block;
  margin: 1rem auto 2rem;
  max-width: 300px;
  object-fit: contain;

  @media (--viewportMedium) {
    max-width: 600px;
  }
}

.primaryButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 260px;
  margin: 45px auto 45px;

  /* Margin */
  margin-bottom: 48px;
}

.smsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.sms {
  display: block;
  max-height: 200px;
  margin-left: 0;
  object-fit: contain;
  border: var(--signaliseGreen) 2px solid;

  @media (--viewportMedium) {
    max-height: 300px;
    margin-left: 25px;
  }
}
