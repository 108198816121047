@import '../../styles/customMediaQueries.css';

.flex {
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}
.title h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  /* letter-spacing: -0.5px; */
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  color: var(--marketplaceColorDark);
  width: 350px;
  letter-spacing: 2px;
  line-height: 40px;
  margin-top: 0;
  padding: 30px;
  background-image: url('../SectionHowItWorks/image/Dotted_rectangle.png');

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    /* letter-spacing: -1px; */
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 400px) {
    background-size: cover;
  }
}

.bookingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 260px;

  /* Margin */
  margin-bottom: 48px;
}

.contentBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
