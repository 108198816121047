@import '../../styles/customMediaQueries.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
@import '../../styles/customMediaQueries.css';

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}
.SectionHero {
  display: flex;
}
/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: .35s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  display: flex;
  margin: 0 24px 0 24px;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row-reverse;
    max-width: 64rem;
    margin: auto;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--marketplaceColorDark);

  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h2 fontWeightRegular from global;
  color: var(--marketplaceColorDark);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0 !important ;
    /* font-size: 125% !important; */

    /* increase font-size for accessibility with Jost */
  }
}

.callsToAction {
  display: flex;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button flexColumn buttonFont buttonText buttonBordersHero buttonColorsHero from global;
  composes: animation;
  max-width: 300px;

  animation-delay: 0.8s;
  border: 2px solid transparent;

  justify-content: center;

  &:hover {
    color: var(--matterColorLight);
    background-color: var(--signaliseDarkPurple);
  }
}

.heroButtonSecondary {
  composes: button flexColumn buttonFont buttonText buttonBordersSecondaryHero buttonColorsSecondaryHero from global;
  composes: animation;

  letter-spacing: 0.2px;

  background: var(--signaliseGreen);
  color: var(--signalisePurple);
  border: 2px solid var(--signalisePurple);
  max-width: 300px;
  margin-bottom: 1rem;

  animation-delay: 0.8s;

  @media (--viewportSmall) {
    margin-bottom: 0;
  }
  &:hover {
    color: var(--matterColorLight);
    background-color: var(--signaliseDarkPurple);
  }
}

.heroHand {
  width: 325px;
  max-width: 100%;
  object-fit: contain;
  order: -1;

  @media (--viewportMedium) {
    width: 375px;
  }
  @media (--viewportLarge) {
    width: 460px;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
