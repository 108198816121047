@import '../../styles/customMediaQueries.css';
.deafUsersList {
  list-style-type: disc;
  list-style: inside;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  @media (--viewportMedium) {
    max-width: 650px;
    margin-right: 50px;
  }
}

.intro {
  margin: 0 0 30px;
}
.contentMain {
  width: 100%;
}

.title h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  /* letter-spacing: -0.5px; */
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  color: var(--marketplaceColorDark);
  width: 350px;
  letter-spacing: 2px;
  margin-top: 0;
  padding: 30px;
  line-height: 40px;
  background-image: url('../SectionHowItWorks/image/Dotted_rectangle.png');

  @media (--viewportMedium) {
    font-size: 40px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    /* letter-spacing: -1px; */
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 500px) {
    background-size: cover;
  }
}

.description {
  display: flex;
  color: var(--marketplaceColorDark);
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: column;
    width: 480px;
  }
}

.videoSection {
  margin: 40px 0 30px;
}
.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.link {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
