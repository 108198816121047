@import '../../styles/customMediaQueries.css';



.EditListingDescriptionForm_formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  composes: EditListingDescriptionForm_formMargins;
}

.features {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.description {
  composes: EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.registration {
  composes: EditListingDescriptionForm_formMargins;
}

div.inputContainer {
  display: flex;
  align-items: center;
}

input[type='file'] {
  border-bottom: none;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.uploadButton {
  max-width: 175px;
  background-color: #2ecc71; /* TODO */
}
.formInputHelperInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.sectionContainer {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}
