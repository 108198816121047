@import '../../styles/customMediaQueries.css';

.title h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  /* letter-spacing: -0.5px; */
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  color: var(--marketplaceColorDark);
  width: 350px;
  letter-spacing: 2px;
  line-height: 40px;
  margin-top: 0;
  padding: 30px;
  background-image: url('../SectionHowItWorks/image/Dotted_rectangle.png');

  @media (--viewportMedium) {
    font-size: 40px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    /* letter-spacing: -1px; */
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 400px) {
    background-size: cover;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  color: var(--marketplaceColorDark);
  align-items: center;
  justify-content: center;
}

.stepGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
    max-width: 1000px;
    margin-bottom: 35px;
  }
}

.stepA {
  order: 0;

  @media (--viewportLarge) {
    margin-right: 130px;
    order: 1;
  }
}

.stepB {
  @media (--viewportLarge) {
    margin-left: 240px;
  }
}

.stepC {
  order: 0;

  @media (--viewportLarge) {
    margin-right: 130px;
    order: 1;
  }
}

.step:last-of-type {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.stepTitle {
  font-size: 24px;

  @media (--viewportLarge) {
    font-size: 30px;
  }
}

.stepText {
  margin-left: 34px;
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.howImage {
  width: 180px;
  margin: 0 20px;
  object-fit: contain;
  @media (--viewportLarge) {
    width: 200px;
  }
}

.ctaLink {
  composes: button buttonFont buttonText buttonBorders from global;

  @media (--viewportMedium) {
    display: block;
    max-width: 200px;
  }
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  &:hover {
    color: var(--signaliseGreen);
    background-color: var(--signaliseDarkPurple);
  }

  max-width: 250px;
}
