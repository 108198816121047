@import '../../styles/customMediaQueries.css';


.root {
}

.marketplaceStroke {
  stroke: var(--marketplaceColor);
}

.attentionStroke {
  stroke: var(--attentionColor);
}
