


.App {
  font-family: 'Jost', Helvetica, Arial, sans-serif;
  text-align: center;
}

.accordion {
  margin: 30px 0 50px 0;
  width: 95%;
}

.label {
  &:hover {
    color: var(--signalisePurple);
  }
}
