@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: auto;
  padding: 11px 24px 0 24px;

  @media (--viewportMedium) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 100px;
  height: 100px;
}
