@import '../../styles/customMediaQueries.css';



  h1 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  ul {
    /*todo: fix  composes: signaliseListStyle from global;*/
  }

  li {
    /*todo: fix composes: signaliseListItemStyle from global;*/
    @media (--viewportMedium) {
      font-size: 18px;
    }
  }

.introSection {
  margin: 30px 0;
}

.field {
  margin-top: 1rem;
}

.error {
  color: red;
}

.elementMargin {
  padding: 0;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 25px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.noBullet li {
  list-style-type: none;
}

.callingMessage {
  color: white;
  background: var(--signalisePurple);
  padding: 15px;
  margin: 30px;
}

.locationNameLabel input {
  font-weight: 600;
  border-bottom-color: var(--signalisePurple);
  outline: none;
}

.locationNameLabel input:hover, .locationNameLabel input:focus {
  border-bottom-color: var(--signaliseGreen);
}
